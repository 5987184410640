@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  /* font-family: "Manrope"; */
}
.container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.main-bg {
  background-color: #121c2d;
  /* background-color: #041460; */
}

.blue-color {
  color: #041460;
}

.bg-color {
  /* background-color: #3556fb !important; */
  /* background: rgb(0, 28, 171);
  background: linear-gradient(
    90deg,
    rgba(0, 28, 171, 1) 38%,
    rgba(53, 86, 251, 1) 100%
  ); */

  background: rgb(2, 10, 51);
  background: linear-gradient(45deg, rgba(2, 10, 51, 1) 38%, #3556fb 100%);
}

.green-color {
  color: #539a0e;
}

.active-link{
  color:#121c2d
}

.container-box {
  max-width: 1250px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.menu-color {
  color: rgb(53, 53, 53);
}

.footer-color {
  color: rgb(175, 175, 175);
}

.footer-bg {
  background-color: #1b1e24;
}

.footer-border {
  border-top: 4px solid;
  -webkit-border-image: -webkit-linear-gradient(
      45deg,
      #ff4ab3 8%,
      #7ff 24%,
      #fcd246 46%,
      #9ffe00 75%,
      #417efd 93%
    )
    1;
  border-image: linear-gradient(
      45deg,
      #ff4ab3 8%,
      #7ff 24%,
      #fcd246 46%,
      #9ffe00 75%,
      #417efd 93%
    )
    1;
}

.testimonial {
  display: flex !important;
  align-items: center;
  gap: 3rem;
}

@media screen and (max-width: 600px) {
  .testimonial {
    display: block !important;
  }
}

.about-bg {
  background: rgb(4, 20, 96);
  background: linear-gradient(
    77deg,
    rgba(4, 20, 96, 1) 0%,
    rgba(53, 86, 251, 1) 35%,
    rgba(53, 86, 251, 1) 100%
  );
}

.green-gradient-bg {
  background: linear-gradient(135deg, #a6e72a 20%, #53ba00 80%);
}

.gray-bg {
  background-color: rgb(230, 230, 230);
}
.out-box{
  width: 100%;
  overflow: hidden;
}

.landing-bg{
  background-color:#121c2d;
  /* border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1; */
  border-bottom-left-radius: 100% 250px;
		border-bottom-right-radius: 100% 250px;
		margin-left: -50px;
    margin-right:-50px;
    overflow: hidden;
}